import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux';


import SimpleBanner from './SimpleBanner';
import translations from '../l10n/terms.json';
import '../assets/styles/BetaForms.css';
import '../assets/styles/Terms.css';

const TextScrollComponent = ({ title, subtitle, text, addTranslation }) => {
  addTranslation(translations);

 
  return (
    <Fragment>
      <SimpleBanner />
      <section id="beta-forms">
        <h1><Translate id={title} /></h1>
        <p><Translate id={subtitle} /></p>
        <p><Translate id={text} /></p>
      </section>
    </Fragment>
  )
}

TextScrollComponent.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
}

export default withLocalize(TextScrollComponent);
