import React, { useState } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import Slider from 'react-slick';
import { connect } from 'react-redux'
import {Redirect} from "react-router";

import translations from '../l10n/services.json';
import '../assets/styles/CommentsList.css';
import '../assets/styles/ServicesList.css';

import {AlertDialog} from './ModalDialogs/AlertDialog';


import * as ROUTES from '../constants/routes';

import arrowImage from '../assets/img/new_landing_images/svg/arrow.svg';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block"}}
      onClick={onClick}
    > 
      <img alt='Next' src={arrowImage} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block"}}
      onClick={onClick}
    > 
      <img alt='Prev' src={arrowImage} />
    </div>
  );
}

const ServicesList = ({ addTranslation, translate, services }) => {
  addTranslation(translations);

  const [popupData, setPopupData] = useState({
    dialogOpen: false,
    dialogTitle: '',
    dialogText: '',
    urlAction: '',
    redirect: false,
  });

  const getServiceByLang = (service, field) => {
    const userLang = navigator.language || navigator.userLanguage;

    switch(field){
      case "title":
        if(userLang.includes('pt')){
          return service.title_pt;
        }
        return service.title_en;
      case "text":
        if(userLang.includes('pt')){
          return service.text_pt;
        }
        return service.text_en;
      case "popup_title":
        if(userLang.includes('pt')){
          return service.popup_title_pt;
        }
        return service.popup_title_en;
      case "popup_text":
        if(userLang.includes('pt')){
          return service.popup_text_pt;
        }
        return service.popup_text_en;
      default:
        if(userLang.includes('pt')){
          return service.title_pt;
        }
        return service.title_en;
    }
  }

  const openDialog = (popupTitle, popupText, urlAction, e) => {
    if(e){
      e.preventDefault();
    }

    setPopupData({
      ...popupData,
      dialogOpen: true,
      dialogTitle: popupTitle,
      dialogText: popupText,
      urlAction: urlAction,
      redirect: false,
    });
  }

  const openPage = (e) => {
    if(e){
      e.preventDefault();
    }
    
    if(popupData && popupData.urlAction){
      setPopupData({
        ...popupData,
        urlAction: popupData.urlAction,
        redirect: true,
      });
    }
  }

  const closeDialog = (e) => {
    if(e){
      e.preventDefault();
    }

    setPopupData({
      ...popupData,
      dialogOpen: false,
      dialogTitle: '',
      dialogText: '',
      urlAction: '',
      redirect: false
    });
  }

  const renderList = () => {
    return services.sort((a, b) => a.weight - b.weight)
      .map(service => {
        const popupTitle = getServiceByLang(service, "popup_title");
        const popupText = getServiceByLang(service, "popup_text");
        const actionUrl = `${ROUTES.SERVICES_FORM}/?service_type=${service.action_url}`;

        return (
          <div className="comment-item"
            key={service.id}>
            <div className="bubble-wrapper">
              <img alt="Icon" className="input-animated" title="Icon" src={process.env.PUBLIC_URL + '/img/icons/' + service.icon} />
              <span className='service-title'>{getServiceByLang(service, "title")}</span>
              <span className='service-text' dangerouslySetInnerHTML={{ __html: getServiceByLang(service, "text") }} />
              <div className="actions-wrapper">
                <a href='https://www.cookerpan.com' onClick={(e) => openDialog(popupTitle, popupText, actionUrl, e)}><Translate id="services.know_more" /></a>
                <a href={actionUrl}><Translate id="services.contact_us" /></a>
              </div>
            </div>
          </div>
        );
    });
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    adaptiveHeight: false,
    arrows: true,
    nextArrow: <SampleNextArrow className='arrow arrowNext' />,
    prevArrow: <SamplePrevArrow className='arrow arrowPrev' />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false
        }
      }
    ]
  };

  const {
    dialogOpen,
    dialogTitle,
    dialogText,
    redirect,
    urlAction
  } = popupData;

  return (
    <section className="content-wrapper services-wrapper" id="services">
      <h2><Translate id="services.title" /></h2>
      <p><Translate id="services.subtitle" /></p>
      <div className="comments-list-wrapper">
        <Slider {...settings}>
            {renderList()}
        </Slider>
      </div>
      {redirect && <Redirect to={urlAction} />}
      { dialogOpen && 
        <AlertDialog
          dialogTitle={ dialogTitle }
          dialogText={ dialogText }
          dialogCancelAction={(e) => closeDialog(e)}
          dialogAcceptAction={(e) => openPage(e)}
          dialogCancelText={translate('services.cancel')}
          dialogAcceptText={translate('services.contact_us')} />}
    </section>
  );
};

const mapStateToProps = state => ({
  services: state.services,
});

export default connect(mapStateToProps)(withLocalize(ServicesList));
