import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Translate, withLocalize } from "react-localize-redux";
import PropTypes from "prop-types";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { useDropzone } from "react-dropzone";
import translations from "../l10n/forms.json";

const BetaForms = ({ userType, addTranslation }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    photos: [],
    message: "",
    website: "",
    country: "Portugal",
    region: "",
    cookExperience: "",
    accept_policy: false,
    shake: "",
  });

  const [submited, toggleSubmited] = useState(false);
  const [submitError, toggleSubmitError] = useState(false);

  const {
    name,
    email,
    phone,
    photos,
    message,
    website,
    country,
    cookExperience,
    region,
    shake,
    accept_policy,
  } = formData;

  const validForm = name && email && accept_policy;

  addTranslation(translations);

  const saveForm = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": process.env.REACT_APP_API_SECRET_KEY,
      },
    };
    
    const reqBody = {
      email,
      form_data: {
        name,
        phone,
        message,
        website,
        photos,
        cookExperience,
        location: `${region}, ${country}`,
      },
      form_type: userType,
    };

    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    await axios
      .post(`/api/forms`, reqBody, config)
      .then(toggleSubmited(true))
      .catch((error) => {
        toggleSubmitError(true);
      });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFormData({
        ...formData,
        photos: photos.concat(acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )),
      });
    },
  });
  const thumbs = photos.map((file) => (
    <div className="thumb" key={file.name}>
      <div className="thumbInner">
        <img src={file.preview} alt="preview" className="img" />
      </div>
    </div>
  ));
  const handleChange = (e) =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validForm) {
      await saveForm();
    } else {
      setFormData({
        ...formData,
        shake: "shake",
      });
    }
  };

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      photos.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [photos]
  );
  return (
    <Translate>
      {({ translate }) => (
        <Fragment>
          {!submited && (
            <form id='form-item' onSubmit={(e) => onSubmit(e)}>
              <div id="form-wrapper">
                <input
                  type="text"
                  name="name"
                  value={name}
                  className={shake}
                  placeholder={translate("form.form-field-name")}
                  onChange={(e) => handleChange(e)}
                />
                <input
                  type="email"
                  name="email"
                  className={shake}
                  value={email}
                  placeholder="Email"
                  onChange={(e) => handleChange(e)}
                />
                <input
                  type="phone"
                  name="phone"
                  value={phone}
                  placeholder={translate("form.form-field-phone")}
                  onChange={(e) => handleChange(e)}
                />
                {userType === "cook" ? (
                  <section className="upload-section">
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input {...getInputProps()} />
                      <p>
                        + Adicione aqui fotografias das suas melhores especialidades
                      </p>
                    </div>
                    <aside className="thumbsContainer">{thumbs}</aside>
                  </section>
                ) : (
                  ""
                )}
                <textarea
                  rows="4"
                  name="message"
                  value={message}
                  placeholder={translate("form.form-field-message")}
                  onChange={(e) => handleChange(e)}
                />
                {userType !== "eater" && (
                  <input
                    type="text"
                    name="website"
                    value={website}
                    placeholder="Website / Instagram URL"
                    onChange={(e) => handleChange(e)}
                  />
                )}
                {userType === "cook" && (
                  <select  onChange={(e) => handleChange(e)} className="cook-experience" name="cookExperience" id="cook-experience">
                    <option value="" disabled selected>Tem experiência anterior como chef/cozinheiro?</option>
                    <option value="yes">Sim</option>
                    <option value="no">Não</option>
                </select>
                )}

                <CountryDropdown
                  id="country-dropdown"
                  defaultOptionLabel={translate("form.form-field-country")}
                  value={country}
                  name="country"
                  onChange={(e) => setFormData({ ...formData, country: e })}
                />
                <RegionDropdown
                  id="region-dropdown"
                  defaultOptionLabel={translate("form.form-field-region")}
                  disableWhenEmpty
                  country={country}
                  name="region"
                  value={region}
                  onChange={(e) => setFormData({ ...formData, region: e })}
                />
                <div>
                  <input
                    type="checkbox"
                    name="accept_policy"
                    className={shake}
                    onChange={(e) =>
                      setFormData({ ...formData, accept_policy: e })
                    }
                  />
                  <label htmlFor="accept_policy" className={shake}>
                    {translate("form.form-rgpd-required")}
                  </label>
                </div>

                <button type="submit">{translate("form.form-apply")}</button>
              </div>
            </form>
          )}
          {submited && (
            <section id="apply-thanks">
              <h2>{translate("form.form-apply-thanks")}</h2>
            </section>
          )}
          {submitError && (
            <section id="submit-error">
              <h2>{translate("form.form-submit-error")}</h2>
            </section>
          )}
        </Fragment>
      )}
    </Translate>
  );
};

BetaForms.propTypes = {
  userType: PropTypes.string,
};

BetaForms.defaultProps = {
  showWebsiteField: "eater",
};

export default withLocalize(BetaForms);
