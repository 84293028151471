import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import SimpleBanner from './SimpleBanner';
import cook from '../assets/img/cook.png';
import plate from '../assets/img/plate.png';
import team from '../assets/img/team.png';
import cookRetina1 from '../assets/img/cook@2x.png';
import plateRetina1 from '../assets/img/plate@2x.png';
import teamRetina1 from '../assets/img/team@2x.png';
import cookRetina2 from '../assets/img/cook@3x.png';
import plateRetina2 from '../assets/img/plate@3x.png';
import teamRetina2 from '../assets/img/team@3x.png';
import * as ROUTES from '../constants/routes';
import '../assets/styles/BetaUsers.css';

const BetaUsers = () => {
  return (
    <Fragment>
      <SimpleBanner />

      <section id="beta-container">
        <h1>ARE YOU A</h1>
        <div id="user-types">
          <Link to={ ROUTES.BETA_COOK }>
            <div id="type" className="grow">
              <img alt="Cook" title="Cook" src={cook} srcSet={cookRetina1 + " 2x, " + cookRetina2 + " 3x"} />
              <h1 >Cook</h1>
            </div>
          </Link>
          <Link to={ ROUTES.BETA_EATER }>
            <div id="type" className="grow">
              <img alt="Eater" title="Eater" src={plate} srcSet={plateRetina1 + " 2x, " + plateRetina2 + " 3x"} />
              <h1 >Eater</h1>
            </div>
          </Link>
          <Link to={ ROUTES.BETA_INFLUENCER }>
            <div id="type" className="grow">
              <img alt="Influencer" title="Influencer" src={team} srcSet={teamRetina1 + " 2x, " + teamRetina2 + " 3x"} />
              <h1 >Influencer</h1>
            </div>
          </Link>
        </div>
      </section>
    </Fragment>
  );
}

export default BetaUsers;
