import React from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import Slider from 'react-slick';
import { connect } from 'react-redux'

import translations from '../l10n/comments.json';
import '../assets/styles/CommentsList.css';

import arrowImage from '../assets/img/new_landing_images/svg/arrow.svg';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block"}}
      onClick={onClick}
    > 
      <img alt='Next' src={arrowImage} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block"}}
      onClick={onClick}
    > 
      <img alt='Prev' src={arrowImage} />
    </div>
  );
}

const CommentsList = ({ addTranslation, comments }) => {
  addTranslation(translations);

  const renderStars = (rating) => {
    var elements = [];
    for (var i = 0; i < 5; i++) {
      if(i < rating){
        elements.push(<span key={i} className="active">&nbsp;</span>);
      }
      else{
        elements.push(<span key={i}>&nbsp;</span>);
      }
    }

    return (
      <div>
        {elements}
      </div>
    );
  }

  const getCommentByLang = (comment) => {
    const userLang = navigator.language || navigator.userLanguage; 
    if(userLang.includes('pt')){
      return comment.text_pt;
    }
    
    return comment.text_en;
  }

  const renderList = () => {
    return comments.sort((a, b) => a.weight - b.weight)
      .map(comment => {
        return (
          <div
            className="comment-item"
            key={comment.id}>
            <a href={comment.url} target="_blank" rel="noopener noreferrer">
              <div className="bubble-wrapper">
                <div className="rating">
                  {renderStars(comment.rating)}
                </div>
                <p>{getCommentByLang(comment)}</p>
              </div>
              <div>
                <div className="profile-pic">
                  <img src={process.env.PUBLIC_URL + '/img/comments/' + comment.profile_pic} alt={comment.profile_name} />
                </div>
                <span>
                  {comment.profile_name}<br></br>
                  <span className="position">{comment.profile_position}</span>

                </span>

              </div>
            </a>
          </div>
        );
    });
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    adaptiveHeight: false,
    arrows: true,
    nextArrow: <SampleNextArrow className='arrow arrowNext' />,
    prevArrow: <SamplePrevArrow className='arrow arrowPrev' />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false
        }
      }
    ]
  };

  return (
    <section className="content-wrapper" id="comments">
      <h2><Translate id="comments.title" /></h2>
      <div className="comments-list-wrapper">
        <Slider {...settings}>
            {renderList()}
        </Slider>
      </div>
    </section>
  );
};

const mapStateToProps = state => ({
  comments: state.comments,
});

export default connect(mapStateToProps)(withLocalize(CommentsList));
