import React, { PureComponent } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { Link } from 'react-scroll';
import { isMobile } from 'react-device-detect';

import translations from '../l10n/banner.json';
import logo from '../assets/img/top_logo_white.png';
import logoRetina1 from '../assets/img/top_logo_white@2x.png';
import logoRetina2 from '../assets/img/top_logo_white@3x.png';
import bannerImage from '../assets/img/new_landing_images/png/screen-feed.png';
import bannerImageRetina1 from '../assets/img/new_landing_images/png/screen-feed@2x.png';
import bannerImageRetina2 from '../assets/img/new_landing_images/png/screen-feed@3x.png';
import logoFacebook from '../assets/img/facebook_icon.png';
import logoInstagram from '../assets/img/instagram_icon.png';
import logoTwitter from '../assets/img/twitter_icon.png';
import logoFacebookRetina1 from '../assets/img/facebook_icon@2x.png';
import logoFacebookRetina2 from '../assets/img/facebook_icon@3x.png';
import logoInstagramRetina1 from '../assets/img/instagram_icon@2x.png';
import logoInstagramRetina2 from '../assets/img/instagram_icon@3x.png';
import logoTwitterRetina1 from '../assets/img/twitter_icon@2x.png';
import logoTwitterRetina2 from '../assets/img/twitter_icon@3x.png';

import playStoreBt from '../assets/img/new_landing_images/png/play-store-icon.png';
import playStoreBt2x from '../assets/img/new_landing_images/png/play-store-icon@2x.png';
import playStoreBt3x from '../assets/img/new_landing_images/png/play-store-icon@3x.png';
import appStoreBt from '../assets/img/new_landing_images/png/app-store-icon.png';
import appStoreBt2x from '../assets/img/new_landing_images/png/app-store-icon@2x.png';
import appStoreBt3x from '../assets/img/new_landing_images/png/app-store-icon@3x.png';

import logoLinkedin from '../assets/img/new_landing_images/svg/linkedin.svg';
import logoReddit from '../assets/img/new_landing_images/svg/reddit.svg';
import logoYoutube from '../assets/img/new_landing_images/svg/youtube.svg';

import '../assets/styles/NewBanner.css';

class Banner extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      scrolling: false,
      topLogo: logo,
      topLogoRetina1: logoRetina1,
      topLogoRetina2: logoRetina2,
      burgerOpen: false
    };

    this.handleScroll = this.handleScroll.bind(this);

    this.props.addTranslation(translations);
  }

  showSettings (event) {
    event.preventDefault();
  }

  componentDidMount() {
      window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
      window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event) {
      if(this.state && window.scrollY === 0 && this.state.scrolling === true) {
          this.setState({
            scrolling: false,
            topLogo: logo,
            topLogoRetina1: logoRetina1,
            topLogoRetina2: logoRetina2
          });
      }
      else if(this.state && window.scrollY > 400 && this.state.scrolling !== true) {
        this.setState({
          scrolling: true,
          topLogo: logo,
          topLogoRetina1: logoRetina1,
          topLogoRetina2: logoRetina2
        });
      }
  }

  handleBurgerClick() {
      this.setState({
          burgerOpen: !this.state.burgerOpen
      });
  }

  isMobileUser(){

    if(window.innerWidth < 1024 || isMobile){
      return true;
    }

    return false;
  }

  render() {
    return (
      <div id="banner" className={(this.state.scrolling && !this.isMobileUser()) ? 'fixed-header' : 'relative-header'}>
        <div className={this.state.burgerOpen ? 'burger-menu open' : 'burger-menu'}
            onClick={this.handleBurgerClick.bind(this)} >
            <span></span>
            <span className="second"></span>
            <span className="third"></span>
        </div>

        <div className="mobile-menu">
            <nav>
            <Link onClick={this.handleBurgerClick.bind(this)} activeClass="active" to="banner" spy={true} smooth={true} offset={0} duration={700} >
                <Translate id="banner.home" />
              </Link>
              <Link onClick={this.handleBurgerClick.bind(this)} activeClass="active" to="best-nearby" spy={true} smooth={true} offset={-120} duration={700} >
                <Translate id="banner.how_it_works" />
              </Link>
              <Link onClick={this.handleBurgerClick.bind(this)} activeClass="active" to="comments" spy={true} smooth={true} offset={-120} duration={700} >
                <Translate id="banner.feedback" />
              </Link>
              <Link onClick={this.handleBurgerClick.bind(this)} activeClass="active" to="store" spy={true} smooth={true} offset={-120} duration={700} >
                <Translate id="banner.download" />
              </Link>
              <Link onClick={this.handleBurgerClick.bind(this)} activeClass="active" to="services" spy={true} smooth={true} offset={-120} duration={700} >
                <Translate id="banner.services" />
              </Link>
              <Link onClick={this.handleBurgerClick.bind(this)} activeClass="active" to="contact" spy={true} smooth={true} offset={-120} duration={700} >
                <Translate id="banner.contact" />
              </Link>
            </nav>

            <div className="social-wrapper-mobile">
              <a href="https://www.facebook.com/cookerpan" target="_blank" rel="noopener noreferrer">
                <img alt="Facebook" className="input-animated" title="Facebook" src={logoFacebook} srcSet={logoFacebookRetina1 + " 2x, " + logoFacebookRetina2 + " 3x"} />
              </a>
              <a href="https://www.instagram.com/cookerpan" target="_blank" rel="noopener noreferrer">
                <img alt="Instagram" className="input-animated" title="Instagram" src={logoInstagram} srcSet={logoInstagramRetina1 + " 2x, " + logoInstagramRetina2 + " 3x"} />
              </a>
              <a href="https://www.twitter.com/cookerpan" target="_blank" rel="noopener noreferrer">
                <img alt="Twitter" className="input-animated" title="Twitter" src={logoTwitter} srcSet={logoTwitterRetina1 + " 2x, " + logoTwitterRetina2 + " 3x"} />
              </a>
              <a href="https://www.linkedin.com/company/cookerpan/" target="_blank" rel="noopener noreferrer">
                  <img alt="LinkedIn" className="input-animated" title="LinkedIn" src={logoLinkedin} />
              </a>
              <a href="https://www.youtube.com/channel/UChIrRNiZp0AYhYRiiKxciFg" target="_blank" rel="noopener noreferrer">
                <img alt="Youtube" className="input-animated" title="Youtube" src={logoYoutube} />
              </a>
              <a href="https://www.reddit.com/r/CookerPan/" target="_blank" rel="noopener noreferrer">
                <img alt="Reddit" className="input-animated" title="Reddit" src={logoReddit} />
              </a>
            </div>
        </div>

        <header>
          <div>
            <a href="https://www.cookerpan.com" className="logo">
              <img alt="Logo" title="Logo" src={this.state.topLogo} srcSet={this.state.topLogoRetina1 + " 2x, " + this.state.topLogoRetina2 + " 3x"}/>
            </a>
            <nav>
              <Link activeClass="active" to="banner" spy={true} smooth={true} offset={0} duration={700} >
                <Translate id="banner.home" />
              </Link>
              <span>&#9679;</span>
              <Link activeClass="active" to="best-nearby" spy={true} smooth={true} offset={-120} duration={700} >
                <Translate id="banner.how_it_works" />
              </Link>
              <span>&#9679;</span>
              <Link activeClass="active" to="comments" spy={true} smooth={true} offset={-120} duration={700} >
                <Translate id="banner.feedback" />
              </Link>
              <span>&#9679;</span>
              <Link activeClass="active" to="store" spy={true} smooth={true} offset={-120} duration={700} >
                <Translate id="banner.download" />
              </Link>
              <span>&#9679;</span>
              <Link activeClass="active" to="services" spy={true} smooth={true} offset={-120} duration={700} >
                <Translate id="banner.services" />
              </Link>
              <span>&#9679;</span>
              <Link activeClass="active" to="contact" spy={true} smooth={true} offset={-120} duration={700} >
                <Translate id="banner.contact" />
              </Link>
            </nav>
          </div>
        </header>
        <div className="content-wrapper">
          <div className="banner-contents">
            <div className="banner-text">
              <h1><Translate id="banner.title" /></h1>
              <p>
                <Translate id="banner.subtitle" />
              </p>
              <div className="buttons-container">
                <a href='https://play.google.com/store/apps/details?id=com.cookerpan' target='_blank' className='store-button' rel="noopener noreferrer" title='Play Store' style={{marginRight: "20px"}}>
                  <img alt="PlayStore" title="PlayStore" src={playStoreBt} srcSet={playStoreBt2x + " 2x, " + playStoreBt3x + " 3x"} />
                </a>
                <a href='https://apps.apple.com/app/com.cookerpan/id1491336076' target='_blank' className='store-button' rel="noopener noreferrer" title='App Store'>
                  <img alt="AppStore" title="AppStore" src={appStoreBt} srcSet={appStoreBt2x + " 2x, " + appStoreBt3x + " 3x"} />
                </a>
              </div>
            </div>
            <div className="banner-image">
              <img alt="Phone" title="Phone" src={bannerImage} srcSet={bannerImageRetina1 + " 2x, " + bannerImageRetina2 + " 3x"} />
            </div>
          </div>
          <div className="arrow-wrapper">
              <i className="arrow down"></i>
            </div>
        </div>
      </div>
    );
  }
}

export default withLocalize(Banner);
