export default function() {
  return [
    {
      id: 1,
      weight: 5,
      url: "http://www.nemacreditoqueesaudavel.com",
      rating: 4,
      text_en: "Love the idea of being able to enjoy homemade food. Fantastic!",
      text_pt: "Adoro a ideia de poder apreciar comida caseira. Fantástico!",
      profile_pic: "nemacreditoqueesaudavel.jpg",
      profile_name: "Nem acredito que é saudável",
      profile_position: "Blogger"
    },
    {
      id: 2,
      weight: 3,
      url: "https://www.instagram.com/gavetad.ingredientes",
      rating: 5,
      text_en: "It's a very interesting concept and quite captivating, specially for Portugal as we associate much the food made by our mothers to comfort! This application, in our opinion, has enormous potential, and will allow us to find the best of cooking in one single click, in one single App!",
      text_pt: "É um conceito bastante interessante e bastante cativante, para nós portugueses pois associamos muito a alimentação feita pelas nossas mães ao conforto! Esta aplicação, a nosso ver, tem um enorme potencial, pois conseguimos encontrar o melhor da cozinha num só clique, numa só App!!",
      profile_pic: "gaveta.jpg",
      profile_name: "Gaveta d'Ingredientes",
      profile_position: "Other"
    },
    {
      id: 3,
      weight: 2,
      url: "https://acozinhaverde.blogs.sapo.pt/",
      rating: 5,
      text_en: "CookerPan is a smart project and suitable for our days. In a time when social networks dominate the market, this platform will allow us to create real connections between clients and suppliers immediately.",
      text_pt: "O CookerPan é um projeto inteligente e adequado aos nossos dias. Numa época em que as redes sociais dominam o mercado, esta plataforma vai permitir criar ligações entre clientes e fornecedores, de forma imediata e real.",
      profile_pic: "acozinhaverde.jpg",
      profile_name: "A cozinha verde",
      profile_position: "Blogger"
    },
    {
      id: 4,
      weight: 4,
      url: "https://www.instagram.com/qualquer_dia_viro_vegan/",
      rating: 5,
      text_en: "Smart, original and useful. We need more ideas like these!",
      text_pt: "Inteligente, original e útil. Precisamos de mais ideias assim!",
      profile_pic: "qualquerdia.jpg",
      profile_name: "Qualquer dia viro vegan",
      profile_position: "Instagrammer"
    },
    {
      id: 5,
      weight: 3,
      url: "https://minhamarmita.blogspot.com/",
      rating: 5,
      text_en: "With CookerPan will be like traveling on a plate without having to move the car, train or plane. There is nothing better than reliving a place through food.",
      text_pt: "Com o CookerPan é como viajar no prato sem ter de me deslocar carro, comboio ou avião. Nao há nada melhor do que reviver um local através da comida.",
      profile_pic: "marmita.jpg",
      profile_name: "Minha marmita",
      profile_position: "Blogger"
    },
    {
      id: 6,
      weight: 1,
      url: "https://www.instagram.com/pombares/",
      rating: 4,
      text_en: "I wish I had this idea .... I hope it will be as relevant and revolutionary as I predict.",
      text_pt: "Gostava de ter sido eu a ter esta ideia.... Espero que venha a ser tão relevante e revolucionária quanto eu vaticino.",
      profile_pic: "pombares.jpg",
      profile_name: "Frederico Pombares",
      profile_position: "Other"
    },
    {
      id: 7,
      weight: 4,
      url: "https://www.instagram.com/portugalflavours/",
      rating: 4,
      text_en: "We're facing a lack of spaces where you can really find tipical food. This can be an interesting solution to show to tourists and Portuguese themselves the best Portugal flavours. We wish you success!",
      text_pt: "Cada vez mais nos deparamos com a falta de espaços de restauração de comida típica em qualquer parte do país. Esta pode ser uma solução interessante de dar a conhecer ao turismo e aos próprios portugueses o que de melhor se faz em Portugal. Desejamo-vos sucesso!",
      profile_pic: "flavours.jpg",
      profile_name: "Portugal Flavours",
      profile_position: "Instagrammer"
    },
    {
      id: 8,
      weight: 2,
      url: "https://www.superfoodschool.com",
      rating: 4,
      text_pt: "Excelente conceito, já vi algumas versões semelhantes em todo o Mundo, por isso existe definitivamente um mercado. Boa sorte!",
      text_en: "Great concept, I've seen a few different versions of this around the world so you definitely have a market. Good luck!",
      profile_pic: "school.jpg",
      profile_name: "Lynnette Astaire",
      profile_position: "Chef"
    },
    {
      id: 9,
      weight: 1,
      url: "https://www.facebook.com/mafaldaagante/",
      rating: 5,
      text_en: "An original idea for those who love cooking and for those who want to enjoy the best flavors of a city.",
      text_pt: "Uma ideia inovadora para quem adora cozinhar e para quem pretende desfrutar dos melhores sabores de uma cidade.",
      profile_pic: "mafalda.jpg",
      profile_name: "Mafalda Agante",
      profile_position: "Blogger"
    }
  ];
}

