import React, { Component } from 'react';
import { withLocalize, Translate } from 'react-localize-redux';
import axios from 'axios';

import translations from '../l10n/footer.json';

import '../assets/styles/Footer.css';

class NotifyForm extends Component {
  constructor(props) {
    super(props);

    this.props.addTranslation(translations);
    this.state = {
        emailValue: '',
        isFormLoading: false,
        isFormSuccess: false,
        isFormError: false,
        formMessage: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({emailValue: event.target.value});
  }

  handleSubmit(event) {
    this.setState({isFormLoading: true});

    const email = {
        email: this.state.emailValue
    };

    axios.post('https://api.cookerpan.com/api/landing/notify', email)
    .then(res => {
        this.setState({
            isFormLoading: false,
            isFormSuccess:true,
            isFormError: false,
            formMessage: "Thank you! We'll keep you posted."
        });
    })
    .catch(res => {
        let errorMessage = "An error occured, please try again later.";
        if(res.response && res.response.data.type && res.response.data.type === "unique"){
            errorMessage = "This email has already been submitted."
        }
        this.setState({
            isFormLoading: false,
            isFormSuccess:false,
            isFormError: true,
            formMessage: errorMessage
        });
    })

    event.preventDefault();
  }

  getDefaultLanguage() {
    const allowedLanguages = ["en", "pt-PT"];
    let defaultLang = "en";
    const browserLanguage = navigator.language || navigator.userLanguage;

    if(allowedLanguages.indexOf(browserLanguage) !== -1){
      defaultLang = browserLanguage;
    }

    return defaultLang;
  }

  render() {
    let submitArea;
    let emailPlaceholder = "Your email address";
    let notifyBtText = "Send";

    if(this.getDefaultLanguage() === "pt-PT"){
        emailPlaceholder = "O teu email";
        notifyBtText = "Enviar";
    }

    if (this.state.isFormLoading) {
        submitArea = (
            <span>
                <input type="email" required="required" placeholder="Your email address"
                        value={this.state.emailValue}
                        onChange={this.handleChange} />
                <div className="loader"></div>
            </span>
        )
    }
    else if(this.state.isFormError){
        submitArea = (
            <span className="error-message">
                {this.state.formMessage}
            </span>
        )
    }
    else if(this.state.isFormSuccess){
        submitArea = (
            <span className="success-message">
                {this.state.formMessage}
            </span>
        )
    }
    else {
        submitArea = (
            <span>
                <input type="email" required="required" placeholder={emailPlaceholder}
                        value={this.state.emailValue}
                        onChange={this.handleChange} />
                <input type="submit" value={notifyBtText} className="input-animated" />
                <span className="icon-send">&nbsp;</span>
            </span>
        )
    }
    return (
        <form onSubmit={this.handleSubmit}>
            <label><Translate id="footer.form_title" /></label>
            <div className="white-wrapper">
                {submitArea}
            </div>
            <span className="respect"><Translate id="footer.form_privacy" /></span>
        </form>
    );
  }
}

export default withLocalize(NotifyForm);
