import { combineReducers } from 'redux';
import comments from './comments';
import services from './services';
import faqs from './faqs';
import licenses from './licenses';

export default combineReducers({
  comments,
  licenses,
  services,
  faqs
});
