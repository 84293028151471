import React from 'react';
import { Translate, withLocalize } from "react-localize-redux";

import translations from "../l10n/store.json";

import playStoreBt from '../assets/img/new_landing_images/png/play-store-icon.png';
import playStoreBt2x from '../assets/img/new_landing_images/png/play-store-icon@2x.png';
import playStoreBt3x from '../assets/img/new_landing_images/png/play-store-icon@3x.png';
import appStoreBt from '../assets/img/new_landing_images/png/app-store-icon.png';
import appStoreBt2x from '../assets/img/new_landing_images/png/app-store-icon@2x.png';
import appStoreBt3x from '../assets/img/new_landing_images/png/app-store-icon@3x.png';

import '../assets/styles/NewStore.css';

const NewStore = ({ addTranslation }) => {
  addTranslation(translations);

  return (
    <section id="store">
      <div className="content-wrapper">
        <div className="text-wrapper">
          <h2><Translate id="store.title" /></h2>
          <p><Translate id="store.subtitle" /></p>

          <a href='https://play.google.com/store/apps/details?id=com.cookerpan' target='_blank' className='store-button' rel="noopener noreferrer" title='Play Store' style={{marginRight: "20px"}}>
            <img alt="PlayStore" title="PlayStore" src={playStoreBt} srcSet={playStoreBt2x + " 2x, " + playStoreBt3x + " 3x"} />
          </a>
          <a href='https://apps.apple.com/app/com.cookerpan/id1491336076' target='_blank' className='store-button' style={{marginLeft: "-16px"}} rel="noopener noreferrer" title='App Store'>
            <img alt="AppStore" title="AppStore" src={appStoreBt} srcSet={appStoreBt2x + " 2x, " + appStoreBt3x + " 3x"} />
          </a>
          <br></br>
        </div>
        <div className="clear"></div>
      </div>
    </section>
  );
};

export default withLocalize(React.memo(NewStore));
