import React, { Component } from "react";
import { Translate, withLocalize } from "react-localize-redux";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";

import translations from "../l10n/banner.json";
import logo from "../assets/img/top_logo_white.png";
import logoRetina1 from "../assets/img/top_logo_white@2x.png";
import logoRetina2 from "../assets/img/top_logo_white@3x.png";
import logoColors from "../assets/img/top_logo_white.png";
import logoColorsRetina1 from "../assets/img/top_logo_white@2x.png";
import logoColorsRetina2 from "../assets/img/top_logo_white@3x.png";
import logoFacebook from "../assets/img/facebook_icon.png";
import logoInstagram from "../assets/img/instagram_icon.png";
import logoTwitter from "../assets/img/twitter_icon.png";
import logoFacebookRetina1 from "../assets/img/facebook_icon@2x.png";
import logoFacebookRetina2 from "../assets/img/facebook_icon@3x.png";
import logoInstagramRetina1 from "../assets/img/instagram_icon@2x.png";
import logoInstagramRetina2 from "../assets/img/instagram_icon@3x.png";
import logoTwitterRetina1 from "../assets/img/twitter_icon@2x.png";
import logoTwitterRetina2 from "../assets/img/twitter_icon@3x.png";
import cookerBanner from "../assets/img/cook_form/cook_banner.jpg";
import logoLinkedin from "../assets/img/new_landing_images/svg/linkedin.svg";
import logoReddit from "../assets/img/new_landing_images/svg/reddit.svg";
import logoYoutube from "../assets/img/new_landing_images/svg/youtube.svg";

import "../assets/styles/FormBanner.css";
import * as ROUTES from "../constants/routes";

class FormBanner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scrolling: false,
      topLogo: logo,
      topLogoRetina1: logoRetina1,
      topLogoRetina2: logoRetina2,
      burgerOpen: false,
    };

    this.handleScroll = this.handleScroll.bind(this);

    this.props.addTranslation(translations);
  }

  showSettings(event) {
    event.preventDefault();
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll(event) {
    if (this.state && window.scrollY === 0 && this.state.scrolling === true) {
      this.setState({
        scrolling: false,
        topLogo: logo,
        topLogoRetina1: logoRetina1,
        topLogoRetina2: logoRetina2,
      });
    } else if (
      this.state &&
      window.scrollY > 400 &&
      this.state.scrolling !== true
    ) {
      this.setState({
        scrolling: true,
        topLogo: logoColors,
        topLogoRetina1: logoColorsRetina1,
        topLogoRetina2: logoColorsRetina2,
      });
    }
  }

  handleBurgerClick() {
    this.setState({
      burgerOpen: !this.state.burgerOpen,
    });
  }

  isMobileUser() {
    if (window.innerWidth < 1024 || isMobile) {
      return true;
    }

    return false;
  }

  render() {
    return (
      <div
        id="form-banner-simple"
        className={
          this.state.scrolling && !this.isMobileUser()
            ? "fixed-header cook-banner"
            : "relative-header cook-banner"
        }
      >
        <div
          className={this.state.burgerOpen ? "burger-menu open" : "burger-menu"}
          onClick={this.handleBurgerClick.bind(this)}
        >
          <span></span>
          <span className="second"></span>
          <span className="third"></span>
        </div>

        <div className="mobile-menu">
          <nav>
            <Link
              onClick={this.handleBurgerClick.bind(this)}
              to={ROUTES.LANDING}
            >
              <Translate id="banner.home" />
            </Link>
            <Link
              onClick={this.handleBurgerClick.bind(this)}
              to={`${ROUTES.LANDING}#best-nearby`}
            >
              <Translate id="banner.how_it_works" />
            </Link>
            <Link
              onClick={this.handleBurgerClick.bind(this)}
              to={`${ROUTES.LANDING}#comments`}
            >
              <Translate id="banner.feedback" />
            </Link>
            <Link
              onClick={this.handleBurgerClick.bind(this)}
              to={`${ROUTES.LANDING}#store`}
            >
              <Translate id="banner.download" />
            </Link>
            <Link
              onClick={this.handleBurgerClick.bind(this)}
              to={`${ROUTES.LANDING}#services`}
            >
              <Translate id="banner.services" />
            </Link>
            <Link
              onClick={this.handleBurgerClick.bind(this)}
              to={`${ROUTES.LANDING}#contact`}
            >
              <Translate id="banner.contact" />
            </Link>
          </nav>

          <div className="social-wrapper-mobile">
            <a
              href="https://www.facebook.com/cookerpan"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt="Facebook"
                className="input-animated"
                title="Facebook"
                src={logoFacebook}
                srcSet={
                  logoFacebookRetina1 + " 2x, " + logoFacebookRetina2 + " 3x"
                }
              />
            </a>
            <a
              href="https://www.instagram.com/cookerpan"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt="Instagram"
                className="input-animated"
                title="Instagram"
                src={logoInstagram}
                srcSet={
                  logoInstagramRetina1 + " 2x, " + logoInstagramRetina2 + " 3x"
                }
              />
            </a>
            <a
              href="https://www.twitter.com/cookerpan"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt="Twitter"
                className="input-animated"
                title="Twitter"
                src={logoTwitter}
                srcSet={
                  logoTwitterRetina1 + " 2x, " + logoTwitterRetina2 + " 3x"
                }
              />
            </a>
            <a
              href="https://www.linkedin.com/company/cookerpan/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt="LinkedIn"
                className="input-animated"
                title="LinkedIn"
                src={logoLinkedin}
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UChIrRNiZp0AYhYRiiKxciFg"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt="Youtube"
                className="input-animated"
                title="Youtube"
                src={logoYoutube}
              />
            </a>
            <a
              href="https://www.reddit.com/r/CookerPan/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt="Reddit"
                className="input-animated"
                title="Reddit"
                src={logoReddit}
              />
            </a>
          </div>
        </div>

        <header id="simple">
          <div>
            <a href="https://www.cookerpan.com" className="logo">
              <img
                alt="Logo"
                title="Logo"
                src={this.state.topLogo}
                srcSet={
                  this.state.topLogoRetina1 +
                  " 2x, " +
                  this.state.topLogoRetina2 +
                  " 3x"
                }
              />
            </a>
            <nav>
              <Link
                onClick={this.handleBurgerClick.bind(this)}
                to={ROUTES.LANDING}
              >
                <Translate id="banner.home" />
              </Link>
              <span>&#9679;</span>
              <Link
                onClick={this.handleBurgerClick.bind(this)}
                to={`${ROUTES.LANDING}#best-nearby`}
              >
                <Translate id="banner.how_it_works" />
              </Link>
              <span>&#9679;</span>
              <Link
                onClick={this.handleBurgerClick.bind(this)}
                to={`${ROUTES.LANDING}#comments`}
              >
                <Translate id="banner.feedback" />
              </Link>
              <span>&#9679;</span>
              <Link
                onClick={this.handleBurgerClick.bind(this)}
                to={`${ROUTES.LANDING}#store`}
              >
                <Translate id="banner.download" />
              </Link>
              <span>&#9679;</span>
              <Link
                onClick={this.handleBurgerClick.bind(this)}
                to={`${ROUTES.LANDING}#services`}
              >
                <Translate id="banner.services" />
              </Link>
              <span>&#9679;</span>
              <Link
                href={`${ROUTES.LANDING}#contact`}
              >
                <Translate id="banner.contact" />
              </Link>
            </nav>
          </div>
        </header>
        <div id="text-wrapper">
          <h1 id="title-form-cook">
            <Translate id={`cook.title`} />
          </h1>
          <p id="text-form-cook">
            <Translate id={`cook.subtitle`} />
          </p>
        </div>
        <div id="button-wrapper" className='cook-form-know'>
          <a href={`https://www.cookerpan.com/cooks/#form-item`}>
            <Translate id={`cook.bt_title`} />
          </a>
        </div>
      </div>
    );
  }
}

export default withLocalize(FormBanner);
