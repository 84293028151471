import React, { Fragment } from 'react'
import { Translate, withLocalize } from 'react-localize-redux';

import SimpleBanner from '../SimpleBanner';
import translations from '../../l10n/licenses.json';

import '../../assets/styles/Faqs.css';
import AndroidIcon from '@material-ui/icons/Android';
import AppleIcon from '@material-ui/icons/Apple';


import { connect } from 'react-redux'


const LicensesWrapper = ({ addTranslation, licenses}) => {

  addTranslation(translations);

  const getLicenseByLang = (license, field) => {
    const userLang = navigator.language || navigator.userLanguage;

    switch(field){
      case "title":
        if(userLang.includes('pt')){
          return license.title_pt;
        }
        return license.title_en;
      case "text":
        if(userLang.includes('pt')){
          return license.text_pt;
        }
        return license.text_pt;
 
      default:
        if(userLang.includes('pt')){
          return license.title_pt;
        }
        return license.title_pt;
    }
  }

  const renderList = () => {
    return licenses
      .map((license) => {
        
        return (
          <div className="tab">
            <input type="radio" id={`rd${license.id}`} name="rd" />
            <label className="tab-label" htmlFor={`rd${license.id}`}>
              <a href={license.url} target='_blank' rel="noopener noreferrer" style={{color: "#000", textDecoration: "none"}}>
                {getLicenseByLang(license, "title")} 
              </a>
              <div style={{"margin-inline-start": "auto"}}>{license.android === true ? <AndroidIcon  /> : <AppleIcon/>}</div>
            </label>
            <div className="tab-content">
              <span dangerouslySetInnerHTML={{ __html: getLicenseByLang(license, "text") }} />
            </div>
          </div>
        );
    });
  }

   
    return (
      <Fragment>
      <SimpleBanner />

      <section id="accordion">
     
        
      
      <h2><Translate id="licenses.title" /></h2>
      
      <div className="tabs">
      
            {renderList()}
      </div>
     
    </section>
    </Fragment>
    
    )
  }


  const mapStateToProps = state => ({
   licenses: state.licenses,
  });
  
export default connect(mapStateToProps)(withLocalize(LicensesWrapper));

