import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { LocalizeProvider } from 'react-localize-redux';

import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './redux';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import './assets/styles/index.css';

ReactDOM.render(
  <LocalizeProvider>
    <Provider store={store}>
      <App />
      <MessengerCustomerChat
        pageId={process.env.REACT_APP_FB_MESSENGER_PAGE_ID}
        appId={process.env.REACT_APP_FB_MESSENGER_APP_ID}
      />
    </Provider>
  </LocalizeProvider>,
  document.getElementById('root')
);
serviceWorker.register();
