import { GET_FAQS } from '../actions/types'

const initialState = {
    faqs: [],
    loading: true,
    error: {}
};

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case GET_FAQS:
            return { ...state, faqs: payload, loading: false };
        default:
            return state;
    }
};
