export const LANDING = "/";
export const FAQS = "/faqs";
export const BETA_USERS = "/beta-users";
export const BETA_COOK = "/cooks";
export const BETA_EATER = "/eaters";
export const BETA_INFLUENCER = "/influencers";
export const SERVICES_FORM = "/services";
export const TERMS_URL = "/terms-and-conditions";
export const PRIVACY_URL = "/privacy-policy";
export const COOKIES_POLICY = "/cookie-policy";
export const APP_LIBRARIES = "/licenses";
