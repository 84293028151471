import React from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
import { Translate, withLocalize } from 'react-localize-redux';

import CookieConsent from "react-cookie-consent";

import NotifyForm from './NotifyForm';
import translations from '../l10n/footer.json';
import logoFooter from '../assets/img/top_logo_white.png';
import logoFacebook from '../assets/img/facebook_icon.png';
import logoFooterWebSummit from '../assets/img/Alpha.png';
import logoInstagram from '../assets/img/instagram_icon.png';
import logoTwitter from '../assets/img/twitter_icon.png';
import logoFooterRetina1 from '../assets/img/top_logo_white@2x.png';
import logoFooterRetina2 from '../assets/img/top_logo_white@3x.png';
import logoFacebookRetina1 from '../assets/img/facebook_icon@2x.png';
import logoFacebookRetina2 from '../assets/img/facebook_icon@3x.png';
import logoInstagramRetina1 from '../assets/img/instagram_icon@2x.png';
import logoInstagramRetina2 from '../assets/img/instagram_icon@3x.png';
import logoTwitterRetina1 from '../assets/img/twitter_icon@2x.png';
import logoTwitterRetina2 from '../assets/img/twitter_icon@3x.png';

import logoLinkedin from '../assets/img/new_landing_images/svg/linkedin.svg';
import logoReddit from '../assets/img/new_landing_images/svg/reddit.svg';
import logoTikTok from '../assets/img/new_landing_images/svg/tiktok.svg';
import logoYoutube from '../assets/img/new_landing_images/svg/youtube.svg';
import pipecodesLogo from '../assets/img/new_landing_images/png/pipecodes.png';
import '../assets/styles/Footer.css';
import * as ROUTES from '../constants/routes';

const Footer = ({ addTranslation, translate}) => {
  addTranslation(translations);

  return (
    <footer id="contact">
      <div className="footer-line">&nbsp;</div>
      <div className="content-wrapper">
        <div>
          <div className="logo-wrapper">
            <img
              alt="CookerPan"
              title="CookerPan"
              src={logoFooter}
              srcSet={logoFooterRetina1 + " 2x, " + logoFooterRetina2 + " 3x"}
            /><br />
            <a href='https://websummit.com/startups/alpha' target='_blank' rel="noopener noreferrer">
              <img
                alt="WebSummit"
                title="WebSummit"
                src={logoFooterWebSummit}
                style={{
                  maxWidth: "55px",
                  marginTop: "20px"
                }}
              />
            </a>
          </div>
          <div className="sitemap-wrapper">
            <ul>
              <li>
                <ScrollLink activeClass="active" to="best-nearby" spy={true} smooth={true} offset={-120} duration={700}>
                  <Translate id="footer.how_it_works" />
                </ScrollLink>
              </li>
              <li>
                <a href="https://www.cookerpan.com/beta-users"><Translate id="footer.join_us" /></a>
              </li>
              <li>
                <a href="https://cookerpan-forum.firebaseapp.com/"><Translate id="footer.help_forum" /></a>
              </li>
              <li>
                <RouterLink to={ROUTES.FAQS}><Translate id="footer.faq" /></RouterLink>
              </li>
              <li>
                <a href='https://blog.cookerpan.com'>Blog</a>
              </li>
              <li>
                <RouterLink to={ROUTES.PRIVACY_URL}><Translate id="footer.privacy_policy" /></RouterLink>
              </li>
              <li>
                <RouterLink to={ROUTES.TERMS_URL}><Translate id="footer.terms" /></RouterLink>
              </li>
            </ul>
          </div>
          <div className="form-wrapper">
            <NotifyForm></NotifyForm>

            <div className='footer-links'>
              <a href="https://www.facebook.com/cookerpan" target="_blank" rel="noopener noreferrer">
                <img alt="Facebook" className="input-animated" title="Facebook" src={logoFacebook} srcSet={logoFacebookRetina1 + " 2x, " + logoFacebookRetina2 + " 3x"} />
              </a>
              <a href="https://www.instagram.com/cookerpan" target="_blank" rel="noopener noreferrer">
                <img alt="Instagram" className="input-animated" title="Instagram" src={logoInstagram} srcSet={logoInstagramRetina1 + " 2x, " + logoInstagramRetina2 + " 3x"} />
              </a>
              <a href="https://www.twitter.com/cookerpan" target="_blank" rel="noopener noreferrer">
                <img alt="Twitter" className="input-animated" title="Twitter" src={logoTwitter} srcSet={logoTwitterRetina1 + " 2x, " + logoTwitterRetina2 + " 3x"} />
              </a>
              <a href="https://www.linkedin.com/company/cookerpan/" target="_blank" rel="noopener noreferrer">
                <img alt="LinkedIn" className="input-animated" title="LinkedIn" src={logoLinkedin} />
              </a>
              <a href="https://www.youtube.com/channel/UChIrRNiZp0AYhYRiiKxciFg" target="_blank" rel="noopener noreferrer">
                <img alt="Youtube" className="input-animated" title="Youtube" src={logoYoutube} />
              </a>
              <a href="https://www.reddit.com/r/CookerPan/" target="_blank" rel="noopener noreferrer">
                <img alt="Reddit" className="input-animated" title="Reddit" src={logoReddit} />
              </a>
              <a href="https://vm.tiktok.com/VGyJw5/" target="_blank" rel="noopener noreferrer">
                <img alt="TikTok" className="input-animated" title="TikTok" src={logoTikTok} />
              </a>
            </div>
          </div>
          <div className="social-wrapper-mobile footer-links">
            <a href="https://www.facebook.com/cookerpan" target="_blank" rel="noopener noreferrer">
              <img alt="Facebook" className="input-animated" title="Facebook" src={logoFacebook} srcSet={logoFacebookRetina1 + " 2x, " + logoFacebookRetina2 + " 3x"} />
            </a>
            <a href="https://www.instagram.com/cookerpan" target="_blank" rel="noopener noreferrer">
              <img alt="Instagram" className="input-animated" title="Instagram" src={logoInstagram} srcSet={logoInstagramRetina1 + " 2x, " + logoInstagramRetina2 + " 3x"} />
            </a>
            <a href="https://www.twitter.com/cookerpan" target="_blank" rel="noopener noreferrer">
              <img alt="Twitter" className="input-animated" title="Twitter" src={logoTwitter} srcSet={logoTwitterRetina1 + " 2x, " + logoTwitterRetina2 + " 3x"} />
            </a>
            <a href="https://www.linkedin.com/company/cookerpan/" target="_blank" rel="noopener noreferrer">
                <img alt="LinkedIn" className="input-animated" title="LinkedIn" src={logoLinkedin} />
            </a>
            <a href="https://www.youtube.com/channel/UChIrRNiZp0AYhYRiiKxciFg" target="_blank" rel="noopener noreferrer">
              <img alt="Youtube" className="input-animated" title="Youtube" src={logoYoutube} />
            </a>
            <a href="https://www.reddit.com/r/CookerPan/" target="_blank" rel="noopener noreferrer">
              <img alt="Reddit" className="input-animated" title="Reddit" src={logoReddit} />
            </a>
            <a href="https://vm.tiktok.com/VGyJw5/" target="_blank" rel="noopener noreferrer">
              <img alt="TikTok" className="input-animated" title="TikTok" src={logoTikTok} />
            </a>
          </div>
        </div>
      </div>
      <div className="copyright">
        ©{new Date().getFullYear()} <Translate id="footer.copyright" />
        <br/>
        <span>Powered by 
          <a href='https://www.pipecodes.com' target='_blank' rel="noopener noreferrer">
            <img src={pipecodesLogo} alt='PipeCodes' style={{
              maxWidth: "95px",
              marginLeft: "9px",
              paddingTop: "13px"
            }}/>
          </a>
        </span>
        <CookieConsent
            location="bottom"
            buttonText={translate("footer.rgpd_accept")}
            cookieName="cookerpan-cookies"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
        >
            <Translate id="footer.rgpd_alert_title" />
            <br />
            <span style={{ fontSize: "12px" }}>
              <Translate id="footer.rgpd_alert_text" />
            </span>
        </CookieConsent>
      </div>
    </footer>
  );
};

export default withLocalize(React.memo(Footer));
