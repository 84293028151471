import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { withLocalize } from 'react-localize-redux';
import { renderToStaticMarkup } from 'react-dom/server';

import Landing from './components/Landing';
import Footer from './components/Footer';
import Faqs from './components/Faqs';
import BetaUsers from './components/BetaUsers';
import BetaApply from './components/BetaApply';
import TextScrollComponent from './components/TextScrollComponent';
import NotFoundPage from './components/NotFoundPage';

import LicensesWrapper from './components/SoftwareLicenses/LicensesWrapper';

import * as ROUTES from './constants/routes';

class App extends Component {
  constructor(props) {
    super(props);

    this.props.initialize({
      languages: [
        { name: "English", code: "en" },
        { name: "Portuguese", code: "pt-PT" }
      ],
      translation: null,
      options: {
        renderToStaticMarkup,
        renderInnerHtml: true,
        defaultLanguage: this.getDefaultLanguage(),
      }
    });
  };

  getDefaultLanguage() {
    const allowedLanguages = ["pt-PT"];
    const browserLanguage = navigator.language || navigator.userLanguage;
    let defaultLang;

    defaultLang = allowedLanguages.includes(browserLanguage) ?
    browserLanguage : "pt-PT";

    return defaultLang;
  };

  render() {
    return (
      <Router>
        <Switch>
          <Route exact path={ ROUTES.LANDING } component={ Landing } />
          <Route exact path={ ROUTES.FAQS } component={ Faqs } />
          <Route exact path={ ROUTES.BETA_USERS } component={ BetaUsers } />
          <Route
            exact
            path={ ROUTES.BETA_COOK }
            render={(props) => <BetaApply {...props} userType="cook" />}
          />
          <Route
            exact
            path={ ROUTES.BETA_EATER }
            render={(props) => <BetaApply {...props} userType="eater" />}
          />
          <Route
            exact
            path={ ROUTES.BETA_INFLUENCER }
            render={(props) => <BetaApply {...props} userType="influencer" />}
          />
          <Route
            exact
            path={ ROUTES.SERVICES_FORM }
            render={(props) => <BetaApply {...props} />}
          />
          <Route
            exact
            path={ ROUTES.APP_LIBRARIES }
            render={(props) => <LicensesWrapper {...props} />}
          />
          <Route
            exact
            path={ ROUTES.TERMS_URL }
            render={(props) => 
              <TextScrollComponent 
                title='terms.title_terms'
                subtitle='terms.subtitle_terms'
                text='terms.text_terms'
                {...props} />
            }
          />
          <Route
            exact
            path={ ROUTES.COOKIES_POLICY }
            render={(props) => 
              <TextScrollComponent 
                title='terms.title_cookies'
                subtitle='terms.subtitle_cookies'
                text='terms.text_cookies'
                {...props} />
            }
          />
          <Route
            exact
            path={ ROUTES.PRIVACY_URL }
            render={(props) => 
              <TextScrollComponent 
                title='terms.title_privacy'
                subtitle='terms.subtitle_privacy'
                text='terms.text_privacy'
                {...props} />
            }
          />
          <Route component={NotFoundPage} />
        </Switch>
        <Footer />
      </Router>
    );
  };
};

export default withLocalize(App);
