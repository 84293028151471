export default function() {
    return [
      {
        id: 1,
        title_pt: "dsad",
        title_en: "dsad",
        text_en: "asdasdasasdasdaddasasddsasdadas",
        text_pt: "Contacta o nosso departamento de segurança e qualidade alimentar. <br/> Torna-te um utilizador verificado, ganha a tua badge e aumenta a tua visibilidade, bem como acesso a outros benefícios.",
        android: false,
        ios: true,
        url: "https://www.cookerpan.com",
      },
      {
        id: 2,
        title_pt: "licenses",
        title_en: "licenasd",
        text_en: "<a href='https://github.com/ReactiveX/RxAndroid#license' target='_blank'>Check more</a>",
        text_pt: "<a href='https://github.com/ReactiveX/RxAndroid#license' target='_blank'>Ver mais</a>",
        android: true,
        ios: false,
        url: "https://www.cookerpan.com",
      },
      {
        id: 3,
        title_pt: "Legal",
        title_en: "Legal",
        text_en: "Our legal department will pleasly help and advice you with your personal finances and how to invoice. <br/>Concentrate your efforts only on your flavoured specialities.",
        text_pt: "O nosso departamento legal terá todo o gosto em ajudar-te a gerir as tuas finanças pessoais e de como podes cumprir as regras e começar a facturar sem problemas. <br/>Concentra-te apenas no sabor dos teus pratos.",
        android: true,
        ios: true,
        url: "https://www.cookerpan.com",
      },
     
    ];
  }
  
  