export default function() {
    return [
      {
        id: 1,
        weight: 5,
        title_pt: "Verified",
        title_en: "Verified",
        text_en: "Contact our quality assurance department. <br/> Became a verified user, get your badge and grow your visibility, while getting access to other benefits.",
        text_pt: "Contacta o nosso departamento de segurança e qualidade alimentar. <br/> Torna-te um utilizador verificado, ganha a tua badge e aumenta a tua visibilidade, bem como acesso a outros benefícios.",
        icon: "verified.svg",
        action_url: "quality",
        popup_title_pt: "Torna-te um Cozinheiro Verified",
        popup_title_en: "Became a Verified Cook",
        popup_text_en: "<ul><li>Quality means everything!</li><li>Some users will only buy from verified cooks.</li><li>We will help you to follow all the rules, in terms of food preparation and certification so you can own your badge.</li></ul>",
        popup_text_pt: "<ul><li>A qualidade é essencial!</li><li>Alguns clientes apenas compram de cozinheiros certificados.</li><li>A nossa equipa irá ajudar-te a cumprir as regras, no que toca a certificação e preparação de comida. Não percas tempo e fala connosco para obter a dua badge.</li></ul>"
      },
      {
        id: 2,
        weight: 5,
        title_pt: "Legal",
        title_en: "Legal",
        text_en: "Our legal department will pleasly help and advice you with your personal finances and how to invoice. <br/>Concentrate your efforts only on your flavoured specialities.",
        text_pt: "O nosso departamento legal terá todo o gosto em ajudar-te a gerir as tuas finanças pessoais e de como podes cumprir as regras e começar a facturar sem problemas. <br/>Concentra-te apenas no sabor dos teus pratos.",
        icon: "legal.svg",
        action_url: "legal",
        popup_title_pt: "Legal e Facturação",
        popup_title_en: "Legal and Invoicing",
        popup_text_en: "<ul><li>At CookerPan we follow the laws and rules.</li><li>You can only post your pans after you insert your NIF/VAT Number.</li><li>To invoince, you should follow the rules and laws in place for your country and our team is here to help you on the process.</li></ul>",
        popup_text_pt: "<ul><li>No CookerPan, seguimos as leis e as regras.</li><li>Apenas conseguirás adicionar os teus pratos após inserção do teu NIF válido.</li><li>Para facturares tens de cumprir as regras do teu país, a nossa equipa ajuda-te nesse processo.</li></ul>",
      },
      {
        id: 3,
        weight: 5,
        title_pt: "Marketing",
        title_en: "Marketing",
        text_en: "At CookerPan, image counts and it's crutial for your success.<br/> Contact us and our food photographers specialists will make sure your plates looke even more appetizing. We wnat you to grow and get results no only on your neighborhood",
        text_pt: "No CookerPan a imagem conta, e é para nós a base do teu sucesso.<br/> Contacta-nos e os nossos fotógrafos de comida terão todo o gosto em tornar os teus pratos ainda mais apetitosos. Queremos que tires o maior proveito e que atinjas resultados ainda mais longe da tua vizinhança.",
        icon: "marketing.svg",
        action_url: "marketing",
        popup_title_pt: "Marketing e Imagem",
        popup_title_en: "Marketing e Imagem",
        popup_text_en: "<ul><li>You know how to cook, but what about photos?</li><li>Have you realized that a good presentation can make you reach higher levels and incomes?</li><li>Talk with us, our team will photograph your best specialities and help you to mount the image just like the higher cook professionals.</li></ul>",
        popup_text_pt: "<ul><li>Sabes cozinhar mas fotografia não é a tua cena?</li><li>Já pensaste bem que uma boa apresentação irá trazer-te resultados bem superiores?</li><li>Fala connosco, a nossa equipa garante uma sessão fotográfica e ajuda a montar a imagem das tuas melhores especialidades.</li></ul>"
      },
      {
        id: 4,
        weight: 5,
        title_pt: "Influencer",
        title_en: "Influencer",
        text_en: "There is a lot to win with CookerPan.<br/> Became one of our ambassadors and win also you with our growth.",
        text_pt: "Há muito a ganhar no CookerPan.<br/> Torna-te um dos nossos embaixadores e ganha também tu com o nosso crescimento.",
        icon: "influencer.svg",
        action_url: "influencer",
        popup_title_pt: "Influencers e Embaixadores",
        popup_title_en: "Influencers and Ambassadors",
        popup_text_en: "<ul><li>Are you a food blogger or influencer?</li><li>Be part of CookerPan while earning.</li><li>Speak with our team and know more how to became an ambassador and what we have to offer you.</li></ul>",
        popup_text_pt: "<ul><li>És um blogger ou influencer ligado à comida?</li><li>Então faz parte do CookerPan e ganha em troca.</li><li>Fala com a nossa equipa e fica a saber mais, torna-te parte de nós e um dos embaixadores e fica a saber o que temos para te oferecer em troca.</li></ul>"
      },
      {
        id: 5,
        weight: 5,
        title_pt: "Programação e Desenvolvimento",
        title_en: "Development",
        text_en: "Integration with other systems?<br/> Our software development team mindset should have something to offer you.",
        text_pt: "Integrações com outros sistemas?<br/> O mindset do nosso departamento de software development tem algo que te pode interessar.",
        icon: "software.svg",
        action_url: "development",
        popup_title_pt: "Programação, Desenvolvimento e Integrações",
        popup_title_en: "Software Development and Integrations",
        popup_text_en: "<ul><li>Do you see on CookerPan any feature that will be helpfull for your business?</li><li>Speak with our development team, we will be happy to build some integrations.</li></ul>",
        popup_text_pt: "<ul><li>Tens um sistema ou negócio que achas que tinha a ganhar com o CookerPan</li><li>Vês alguma coisa no CookerPan que se adaptaria bem ao teu negócio?</li><li>O nosso departamento de software development está aberto a novas parcerias, fala connosco.</li></ul>"
      },
      {
        id: 6,
        weight: 5,
        title_pt: "Escola de Cozinha",
        title_en: "Cooking School",
        text_en: "What if you don't know how to cook or want to improove your skills and create your own job?<br /> Talk with us and we will make sure to invite you to our cooking workshops.",
        text_pt: "E se tu não sabes cozinhar ou queres melhorar as tuas skills e criar o teu próprio emprego? Fala connosco para ganrantir a tua presença num dos nossos workshops.",
        icon: "school.svg",
        action_url: "workshop",
        popup_title_pt: "CookerPan - Workshops",
        popup_title_en: "CookerPan School and Workshops",
        popup_text_en: "<ul><li>Do you want to learn cooking?</li><li>Do you want to earn more incomes or create your own job?</li><li>Speak with us and we will include you in our workshops or connect you with our partners.</li></ul>",
        popup_text_pt: "<ul><li>Sempre quiseste aprender a cozinhar?</li><li>Vias-te a ganhar uns trocos enquanto cozinhas?</li><li>Fala connosco, temos workshops e parcerias que te podem ajudar a criar o teu próprio emprego</li></ul>"
      }
    ];
  }
  
  