import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export class AlertDialog extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
        loading: false,
        open: true
    };
  }

  handleClickOpen = () => {
    this.setState({open: true});
  }

  handleClose = () => {
    this.setState({open: false});
  };

  render(){
    const {
      dialogTitle, 
      dialogText, 
      dialogCancelAction, 
      dialogAcceptAction, 
      dialogCancelText, 
      dialogAcceptText
    } = this.props;

    return (
      <div>
        <Dialog
          open={this.state.open}
          onClose={() => this.handleClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <span dangerouslySetInnerHTML={{ __html: dialogText }} />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={dialogCancelAction} color="primary">
              {dialogCancelText}
            </Button>
            <Button onClick={dialogAcceptAction} color="primary" autoFocus>
              {dialogAcceptText}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}