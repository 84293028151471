import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Translate, withLocalize } from 'react-localize-redux';

import { getFaqs } from '../redux/actions/faqs';
import SimpleBanner from './SimpleBanner';
import translations from '../l10n/faqs';
import '../assets/styles/Faqs.css';

const Faqs = ({ addTranslation, getFaqs, faqs: { loading, faqs } }) => {
  addTranslation(translations);

  useEffect(() => {
    getFaqs();
  }, [getFaqs]);

  return (
    <Fragment>
      <SimpleBanner />

      <section id="accordion">
        <h1>FAQ</h1>

        <Translate id="faqs.intro" /><p></p>

        <div className="tabs">
        { loading ?
          /* show loader while content is retrieved from db */
          <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
          :
          faqs.data.length > 0 ? (faqs.data.map((faq, index) => (
          <div className="tab">
            <input type="radio" id={`rd${index}`} name="rd" />
            <label className="tab-label" htmlFor={`rd${index}`}>{faq.title}</label>
            <div className="tab-content">
              {faq.text}
            </div>
          </div>))) : <p id="no-faqs"><Translate id="faqs.no-faqs" /></p>
        }
        </div>
      </section>
    </Fragment>
  );
};

Faqs.propTypes = {
  getFaqs: PropTypes.func.isRequired,
  faqs: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  faqs: state.faqs,
});

export default connect(mapStateToProps, { getFaqs })(withLocalize(React.memo(Faqs)));
