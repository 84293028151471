import axios from 'axios'
import { GET_FAQS, FAQ_ERROR } from './types'

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

// Get faqs
export const getFaqs = () => async dispatch => {
  const config = {
      headers: {
          'x-auth-token': process.env.REACT_APP_API_SECRET_KEY
      }
  };

  try {
    const res = await axios.get('/api/faqs', config);

    dispatch({
      type: GET_FAQS,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: FAQ_ERROR,
      payload: { msg: error.response.data.msg, status: error.response.status }
    });
  }
};
