import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Translate, withLocalize } from "react-localize-redux";

import { useLocation } from "react-router-dom";

import FormBanner from "./FormBanner";
import SimpleBanner from "./SimpleBanner";
import BetaForms from "./BetaForms";
import translations from "../l10n/forms.json";
import "../assets/styles/BetaForms.css";

const BetaApply = ({ userType, addTranslation }) => {
  addTranslation(translations);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  let query = useQuery();
  if (query && query.get("service_type")) {
    userType = query.get("service_type");
  }

  return (
    <Fragment>
      {userType === "cook" ? <FormBanner /> : <SimpleBanner />}
      {userType === "cook" ? (
        <>
           <section id="beta-forms">
           <BetaForms userType={userType} />
         </section>
         </>
      ) : (
        <section id="beta-forms">
          <h1>
            <Translate id={`form.form-title-${userType}`} />
          </h1>
          <p>
            <Translate id={`form.form-text-${userType}`} />
          </p>
          <BetaForms userType={userType} />
        </section>
      )}
    </Fragment>
  );
};

BetaApply.propTypes = {
  userType: PropTypes.string,
};

export default withLocalize(BetaApply);
