import React from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import ScrollAnim from 'rc-scroll-anim';

import translations from '../l10n/app_screens.json';

import '../assets/styles/About.css';
import '../assets/styles/AppScreens.css';

const ScrollParallax = ScrollAnim.Parallax;

const AppScreenBlock = ({ addTranslation, id, title, text, buttonText, buttonUrl,
    appImage, appImage2x, appImage3x, imageLeft }) => {
  addTranslation(translations);

  return (
    <section className="content-wrapper app-screens" id={id}>
      <div className={ `about-wrapper flex-wrapper ${imageLeft ? 'right' : 'left'}` } style={{
          flexDirection: imageLeft ? "row" : "row-reverse"
      }}>
        <img alt={title} title={title} src={appImage} srcSet={appImage2x + " 2x, " + appImage3x + " 3x"} />
        {
          imageLeft ? 
          (<ScrollParallax animation={{marginRight:"0px", opacity: 1, playScale: [0.5, 0.7]}}>
            <div className="about-text">
                <h3><Translate id={title} /></h3>
                <p>
                  <Translate id={text} />
                </p>
                {
                  buttonUrl && (
                    <a className='bt-action' href={buttonUrl} target='_blank' rel="noopener noreferrer">
                      <Translate id={buttonText} />
                    </a>
                  )
                }
                
            </div>
          </ScrollParallax>) : 
          (<ScrollParallax animation={{marginLeft:"0px", opacity: 1, playScale: [0.5, 0.7]}}>
            <div className="about-text left">
                <h3><Translate id={title} /></h3>
                <p>
                  <Translate id={text} />
                </p>
                {
                  buttonUrl && (
                    <a className='bt-action' href={buttonUrl} target='_blank' rel="noopener noreferrer">
                      <Translate id={buttonText} />
                    </a>
                  )
                }
            </div>
          </ScrollParallax>)
        }
        
        
      </div>
    </section>
  );
};

export default withLocalize(React.memo(AppScreenBlock));
