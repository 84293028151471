import React, { Fragment } from 'react';

import NewBanner from './NewBanner';
import CommentsList from './CommentsList.js';

import ServicesList from './ServicesList.js';

import NewStore from './NewStore';
import AppScreenBlock from './AppScreenBlock';

import screenNearby from '../assets/img/new_landing_images/png/screen-nearby.png';
import screenNearby2x from '../assets/img/new_landing_images/png/screen-nearby@2x.png';
import screenNearby3x from '../assets/img/new_landing_images/png/screen-nearby@3x.png';

import screenCook from '../assets/img/new_landing_images/png/screen-cook.png';
import screenCook2x from '../assets/img/new_landing_images/png/screen-cook@2x.png';
import screenCook3x from '../assets/img/new_landing_images/png/screen-cook@3x.png';

import screenRating from '../assets/img/new_landing_images/png/screen-rating.png';
import screenRating2x from '../assets/img/new_landing_images/png/screen-rating@2x.png';
import screenRating3x from '../assets/img/new_landing_images/png/screen-rating@3x.png';

const Landing = () => {
  return (
    <Fragment>
      <NewBanner />
      <AppScreenBlock 
        id='best-nearby'
        title='best_nearby.title' 
        text='best_nearby.subtitle'
        buttonText='best_nearby.bt_title'
        buttonUrl='https://www.cookerpan.com/clients.pdf'
        appImage={screenNearby} 
        appImage2x={screenNearby2x}
        appImage3x={screenNearby3x}
        imageLeft={true}/>
      <AppScreenBlock 
        id='cook'
        title='cook.title' 
        text='cook.subtitle'
        buttonText='cook.bt_title'
        buttonUrl='https://www.cookerpan.com/cooks.pdf'
        appImage={screenCook} 
        appImage2x={screenCook2x}
        appImage3x={screenCook3x}
        imageLeft={false}/>
      <AppScreenBlock 
        id='money'
        title='money.title' 
        text='money.subtitle' 
        appImage={screenRating} 
        appImage2x={screenRating2x}
        appImage3x={screenRating3x}
        imageLeft={true}/>
      <CommentsList/>
      <NewStore />
      <ServicesList />
    </Fragment>
  );
};

export default React.memo(Landing);
