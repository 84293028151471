import React, { Fragment } from 'react'
import { Translate, withLocalize } from 'react-localize-redux';


import SimpleBanner from './SimpleBanner';
import translations from '../l10n/notfound.json';
import '../assets/styles/BetaForms.css';
import notFoundImage from '../assets/img/new_landing_images/svg/plate.svg';

const NotFoundPage = ({ userType, addTranslation }) => {
  addTranslation(translations);

  return (
    <Fragment>
      <SimpleBanner />

      <section id="beta-forms">
        <h1><Translate id={`notfound.title`} /></h1>
        <img alt='Not Found' title='Not Found' src={notFoundImage} style={{maxWidth: "200px"}} />
        <br /><br />
        <a href='https://www.cookerpan.com'>Homepage</a>
      </section>
    </Fragment>
  )
}

export default withLocalize(NotFoundPage);
